function SearchGeoBoxModule(target) {
    var self = this
    var SEARCH_DEFAULT = 'offers'
    //var SEARCH_DEFAULT = 'caterings'

    self.error = ko.observable(false)
    self.address = ko.observable(null)
    self.target = ko.observable(target ? target : SEARCH_DEFAULT)
    self.query = ko.observable(null)
    self.loading = ko.observable(false)
    self.keywords = ko.observable(window.TROVACIGUSTO && TROVACIGUSTO.query ? TROVACIGUSTO.query.keywords : null)

    self.searchButton = function () {
        self.search(null, null, null)
    };

    self.searchByPositionButton = function () {

        //@FIXME import missing function
        // pNotify('Ricerca in corso vicino a te');
        navigator.geolocation.getCurrentPosition(self.searchByPosition, self.errorPosition, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });
    };

    self.errorPosition = function (err) {
        if (err.code === 1)
            pNotify('You have not authorized access to your location', 'error');
        if (err.code === 2)
            pNotify('Unable to determine your location. Please enter the address manually.', 'warning');
        if (err.code === 3)
            pNotify('Unable to determine your location. Please enter the address manually.', 'warning');
    };

    self.searchByPosition = function (position) {
        self.search(position.coords.latitude, position.coords.longitude, position.coords.latitude + position.coords.longitude);
    };

    self.search = function (lat, lng, keywords) {
        self.loading(true);
        var args = [];
        if (self.query()) {
            args.push(self.query())
        }
        if (self.address() && self.address().geometry) {
            args.push(
                "lat=" + self.address().geometry.location.lat(),
                "lng=" + self.address().geometry.location.lng(),
                "keywords=" + self.address().formatted_address //@TODO rimuovere dalla barra di ricerca
            );
            location.href = TROVACIGUSTO.app_url + "/search/" + self.target() + "/?" + args.join("&");
            self.loading(false);
            return;
        }
        if (lat && lng) {
            args.push(
                "lat=" + lat,
                "lng=" + lng,
                "keywords=" + (self.firstSuggestion ? self.firstSuggestion : "vicino a te")
            );
            location.href = TROVACIGUSTO.app_url +"/search/" + self.target() + "/?" + args.join("&");
            self.loading(false);

            return;
        }
        if (keywords) {
            var par = getUrlParameterValue('keywords');
            if (par !== keywords) {
                args.push("keywords=" + keywords);
                args.push("mode=keywords");
                location.href = TROVACIGUSTO.app_url + "/search/" + self.target() + "/?" + args.join("&");
            }
            self.loading(false);
            return;
        }

        self.geocode();
        self.loading(false);

    };

    // Anytime address is changed we immediately trigger a search.
    // The ko-autocomplete plugin will set this value only when
    // object.geometry is set.
    self.address.subscribe(function () {
        self.enterBehaviour = 'geocoded';
    });

    self.firstSuggestion = null;
    if (typeof google !== 'undefined' && google.maps && google.maps.Geocoder)
        self.googleGeocoder = new google.maps.Geocoder();

    // Enter key behaviour. We expect these behaviours:
    //  user hit the enter key to choose a google suggestion (after moving with arrow keys):
    //      in this case we switch bejaviour to 'geocoded' and wait for next input (click or enter)
    //  user hit the enter key without choosing a valid suggestion: we fetch the first one
    //  and trigget the search.
    // So,
    //  - 'default' behaviour triggers the search with the first suggestion
    //  - 'geocoded' wait for next user input
    self.enterBehaviour = 'default';

    self.keyPress = function (e) {

        // Enter button.
        if (e.which === 13) {
            e.preventDefault();

            if (self.enterBehaviour === 'geocoded' && self.address() === null) {

            } else if (self.enterBehaviour === 'geocoded' && self.address()) {
                //  self.search();
            } else {
                // When address is null we assume the first google suggestion
                // as the intended search term.
                if (self.address() === null) {
                    // self.geocode();
                }
            }
        }
    };

    $('#search-box').find('input').focus(function () {
        $(document).keypress(function (e) {
            self.keyPress(e);
        });
    }).blur(function () {
        $(document).unbind('keypress');
    });

    self.geocode = function () {
        var lat = null, lng = null;

        self.firstSuggestion = $('.pac-container .pac-item:first .pac-item-query').text();
        if (!self.firstSuggestion && self.keywords()) {
            self.search(null, null, self.keywords());
        }
        if (self.googleGeocoder) {
            self.googleGeocoder.geocode({"address": self.firstSuggestion}, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    lat = results[0].geometry.location.lat();
                    lng = results[0].geometry.location.lng();
                    self.search(lat, lng, null);
                }
            });
        }
    }
}