$(document).ready(function () {

    $(document).on('click', '#btn-partecipa', function () {
        $.fn.fullpage.moveSectionDown();
    });
    $(document).on('click', '#btn-page5', function () {
        $.fn.fullpage.moveTo(5);

    });
    $(document).on('click', '#btn-page3', function () {
        $.fn.fullpage.moveTo(3);

    });
    $(document).on('click', '#btn-page4', function () {
        $.fn.fullpage.moveTo(4);

    });
    $(document).on('click', '#btn-page6', function () {
        $.fn.fullpage.moveTo(6);

    });
    $(document).on('click', '#btn-page7', function () {
        $.fn.fullpage.moveTo(7);

    });
    $(document).on('click', '#btn-page8', function () {
        $.fn.fullpage.moveTo(8);
    });
    $(document).on('click', '#btn-page9', function () {
        $.fn.fullpage.moveTo(9);
    });
    $(document).on('click', '#btn-page10', function () {
        $.fn.fullpage.moveTo(10);
    });
    $(document).on('click', '#btn-page11', function () {
        $.fn.fullpage.moveTo(11);
    });
    $(document).on('click', '#btn-page12', function () {
        $.fn.fullpage.moveTo(12);
    });
    $(document).on('click', '#btn-page13', function () {
        $.fn.fullpage.moveTo(13);
    });
    $(document).on('click', '#btn-page14', function () {
        $.fn.fullpage.moveTo(14);
    });
    $(document).on('click', '.btn-down', function () {
        $.fn.fullpage.moveSectionDown();
    });
    $(document).on('click', '.btn-top', function () {
        if ($(window).width() < 725) {
            $('body,html').animate({
                scrollTop: 0
            }, 1000);
        } else {
            $.fn.fullpage.moveTo(1);
        }
    });
    $(document).on('click', '.link-btn', function () {
        location.href = $(this).attr('data-href');
    });

    if (document.getElementById('comingsoonhome')) {
        if (getCookie('comingsoonhome')) {
            document.getElementById('comingsoonhome').style.display = 'none'
        } else {
            document.getElementById('comingsoonhome').style.display = null
        }
    }


});


function HomePageViewModel() {

    var self = this;
    self.error = ko.observable(false);
    self.homeType = ko.observable((typeof TROVACIGUSTO !== 'undefined' && TROVACIGUSTO.homeType) ? TROVACIGUSTO.homeType : '');

    var TROVACIGUSTO_SHARE_TEXT = 'I invite you to discover FindGusto, the platform that makes it easy to enjoy great food every day, and aims to lift and improve the world using food as a lever!';
    var TROVACIGUSTO_SHARE_URL = window.location.origin;

    self.socialShareTrovacigusto = new SocialShareModule(TROVACIGUSTO_SHARE_TEXT, TROVACIGUSTO_SHARE_URL);

    self.searchCatering = function () {
        trovacigusto.modalSearch.search();
    };

    function promoFindGusto(date) {
        var endDate = moment(date, ['DD.MM.YYYY', 'DD-MM-YYYY']);
        var eventTime = endDate.unix();
        var currentTime = moment().unix();
        var diffTime = eventTime - currentTime;

        dateCountdown(diffTime, '.findGusto', 'd [Days and] hh[h] mm[m] ss[s]');
    }

    promoFindGusto('01.03.2020');


    if (self.homeType() !== 'user' && self.homeType() !== 'staff') {
        self.promoCampaign = new CampaignModule(1, '.promoCounter');
        self.promoCampaign.initCampaign();
    }

    self.init = function () {
        switch (self.homeType()) {
            case 'user':
                trovacigusto.modalRegister.templateIntro('register-intro-user');
                trovacigusto.modalRegister.headerLabel('Create your free account');
                break;
            case 'catering':
                trovacigusto.modalRegister.headerLabel('New Business? Create your business account. ACTIVATE IT FOR FREE NOW!')
                break;
            case 'supplier':
                trovacigusto.modalRegister.headerLabel('New Supplier?');
                break;
            case 'salesman':
                trovacigusto.modalRegister.headerLabel('New Agent?');
                break;
            case 'staff':
                trovacigusto.modalRegister.headerLabel('New user "Staff & Pro"?');
                break;
            case 'delivery':
                trovacigusto.modalRegister.headerLabel('New Delivery person?');
                break;
            case 'food-guru':
                trovacigusto.modalRegister.headerLabel('New Food Guru?');
                break;
            case 'chef':
                trovacigusto.modalRegister.headerLabel('New Home Chef business?');
                break;
            case 'virtual-catering':
                trovacigusto.modalRegister.headerLabel('Create your Virtual Restaurant');
                break;
            case 'canteen':
                trovacigusto.modalRegister.headerLabel('Create your Virtual Restaurant');
                break;
            case 'canteen-group':
                trovacigusto.modalRegister.headerLabel('Create your Virtual Canteen group');
                break;
            default:
                trovacigusto.modalRegister.templateIntro('register-intro');
                trovacigusto.modalRegister.headerLabel('New User? FindGusto!');
                break;
        }
    };

    self.switchToDelivery = function () {
        self.switchToCatering('delivery');
    };

    self.switchToFoodGuru = function () {
        self.switchToCatering('foodguru');
    };

    self.switchToCatering = function (type) {
        if (!type)
            type = 'catering';
        var user = trovacigusto.userLogged();
        if (user && user.status === "enabled" && !user.catering)
            rest('GET', '/api/v2/users/' + user.id + '/activate/' + type)
                .then(function (response) {
                    if (response.success) {
                        location.replace('/catering/activate/');
                    } else {
                        if (response.status === 'account.switch.delivery') {
                            Swal.fire({
                                title: 'We are sorry, but you cannot activate your Courier business account yet',
                                html: 'Your personal profile has not yet received the 10 preferences required as a standard prerequisite for activation',
                                type: 'warning',
                                showConfirmButton:false
                            });
                        }
                        if (response.status === 'account.switch.foodguru') {
                            Swal.fire({
                                title: 'We are sorry, but you cannot activate your Food Guru business account yet',
                                html: 'Your personal profile has not yet reached the 100 followers required as a standard prerequisite for activation',
                                type: 'warning',
                                showConfirmButton:false
                            });
                        }
                    }
                }).catch(function () {

            });
        else {
            location.href = '/';
        }
    };

    self.findGustoPromoLogin = function () {
        if (trovacigusto.userLogged())
            location.replace('/');
        else
            trovacigusto.modalRegister.show()
    };


    self.dismiss = function () {
        setCookie('comingsoonhome', true, 7)
    }

    self.action = function () {
        if (!trovacigusto.userLogged()) {
            window.location.href = '/register'
            //trovacigusto.modalRegister.show()
        } else {
            if (trovacigusto.userLogged().catering) {
                window.location.href = '/catering/' + trovacigusto.userLogged().catering.id + '/admin/invite'
            } else {
                window.location.href = '/user/favorite/caterings#locali'
            }
        }
    }

    self.init();

    return self;

}

if (document.getElementById('content-bind')) {
    ko.applyBindings(new HomePageViewModel(), document.getElementById('content-bind'));
}